<!-- eslint-disable -->
<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Ajouter une livraison
            </div>

            <div class="subtitle-1 font-weight-light">
              Remplir vos informations de livraison
            </div>
          </template>

          <b-form @submit.prevent="createDelivery">
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="provider"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field required
                    class="purple-input"
                    label="Nom de l'expéditeur"
                    v-model="clientname"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="N° Téléphone de l'expéditeur"
                    class="purple-input"
                    v-model="phone"
                  />
                </v-col>
                <v-col
                style="margin-top:-30px"
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="provider"
                    disabled
                  />
                </v-col>

                <v-col
                style="margin-top:-30px"
                  cols="12"
                  md="4"
                >
                  <v-text-field required
                    class="purple-input"
                    label="Nom de receveur"
                    v-model="receivername"
                  />
                </v-col>
                <v-col
                style="margin-top:-30px"
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="N° Téléphone de receveur"
                    class="purple-input"
                    v-model="receiverphone"
                  />
                </v-col>
                <v-row v-if="checked==true">
                  <v-col
                  cols="12"
                  md="3"
                >
                  <label>Dépot de départ</label>
                  <b-form-select v-model="selectedDepot1" @change="getFees" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>Wilaya de départ</label>
                  <b-form-select v-model="selectedWilaya1"  required>
                    <option v-for="wilaya in allwilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue de départ</label>
                  <b-form-select v-model="selectedCommunue1" required>
                    <option v-for="wilaya in selectedWilaya1.communues" :key="wilaya.name" :value="wilaya.name">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="Adresse de départ"
                    class="purple-input"
                    v-model="addr1"
                  />
                </v-col>
                </v-row>
                <v-row v-else>
                  <v-col
                  cols="12"
                  md="3"
                >
                  <label>Dépot de départ</label>
                  <b-form-select v-model="selectedDepot1" @change="getFees" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>Wilaya de départ</label>
                  <b-form-select v-model="selectedWilaya1" disabled>
                    <option v-for="wilaya in allwilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue de départ</label>
                  <b-form-select v-model="selectedCommunue1" disabled>
                    <option v-for="wilaya in selectedWilaya1.communues" :key="wilaya.name" :value="wilaya.name">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Adresse de départ"
                    class="purple-input"
                    v-model="addr1"
                    disabled
                  />
                </v-col>
                </v-row>
                <v-row v-if="checked2==true">
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Dépot d'arrivée</label>
                  <b-form-select v-model="selectedDepot2" @change="getFees" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>Wilaya d'arrivée</label>
                  <b-form-select v-model="selectedWilaya2" required>
                    <option v-for="wilaya in allwilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue d'arrivée</label>
                  <b-form-select v-model="selectedCommunue2" required>
                    <option v-for="wilaya in selectedWilaya2.communues" :key="wilaya.name" :value="wilaya.name">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="Adresse d'arrivée"
                    class="purple-input"
                    v-model="addr2"
                  />
                </v-col>
                </v-row>
                <v-row v-else>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Dépot d'arrivée</label>
                  <b-form-select v-model="selectedDepot2" @change="getFees" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>Wilaya d'arrivée</label>
                  <b-form-select v-model="selectedWilaya2" disabled>
                    <option v-for="wilaya in allwilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue d'arrivée</label>
                  <b-form-select v-model="selectedCommunue2" disabled>
                    <option v-for="wilaya in selectedWilaya2.communues" :key="wilaya.name" :value="wilaya.name">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    disabled
                    label="Adresse d'arrivée"
                    class="purple-input"
                    v-model="addr2"
                  />
                </v-col>
                </v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="Marchandises"
                    class="purple-input"
                    v-model="produit"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    required
                    label="Poids (Par Kg)"
                    class="purple-input"
                    v-model="weight"
                    @input="changeFees"
                    type="number"
                    step="any"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    label="N° de colis"
                    class="purple-input"
                    v-model="quantity"
                    @input="changeFees"
                    type="number"
                    step="any"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"                
                >
                <b-form-select v-model="checked2" @change="getFees" required>
                  <option :value="true">A domicile</option>
                  <option v-if="selectedDepot2.is_stop_desk" :value="false">Stop Desk (point relai)</option>
                </b-form-select>
                <!-- <b-form-checkbox class="myDIV" v-model="checked" name="check-button" switch @change="getFees" v-b-tooltip.hover title="Le chauffeur va ramasser le colis d'après l'adresse de départ que vous entrer">Ramasser <b>(Checked: {{ checked }})</b></b-form-checkbox>
                <b-form-checkbox class="myDIV" v-model="checked2" name="check-button" switch @change="getFees" v-b-tooltip.hover title="Le chauffeur va livrer le colis à l'adresse de d'arrivé que vous entrer">Domicile <b>(Checked: {{ checked2 }})</b></b-form-checkbox> -->
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <label>Type de paiement</label>
                  <b-form-select v-model="payment_method" @change="getFees" required>
                    <option v-for="payment_method in ['Acheteur couvre tous les frais','Acheteur couvre juste les frais de colis','Acheteur couvre juste les frais de livraison','Vendeur couvre tous les frais']" :key="payment_method" :value="payment_method">{{payment_method}}</option>
                  </b-form-select>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    required
                    label="Prix"
                    class="purple-input"
                    v-model="price"
                    @input="changeFees"
                    type="number"
                    step="any"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    disabled
                    label="Prix de livraison"
                    class="purple-input"
                    v-model="deliveryPrice"
                    type="number"
                    step="any"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    class="purple-input"
                    label="Plus de détails (Max 1000 caractères, sinon laisser vide)"
                    v-model="text"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <!-- <p  v-if="responseMessage != null" style="color:red;">{{responseMessage}}</p> -->
                  <p  v-if="totalPrice!=null" style="color:green;">Prix total : {{totalPrice}}</p>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <p  v-if="tracking_id!=null" style="color:green;">*Votre colis a été crée avec success*<br> Votre Tracking ID est  : {{tracking_id}}</p>
                </v-col>


                <v-col
                  cols="12"
                  md="4"
                  class="text-right"
                >
                  <v-btn
                    type='submit'
                    color="success"
                    class="mr-0"
                  >
                    Creer une livraison
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </b-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
  import axios from 'axios'
  export default {
    //
    data() {
      return{
        vehicules: ['Fourgon 400 KG','Fourgon 1.5 TN', 'Camion 2.5 TN', 'Camion 3.5 TN', 'Camion 10 TN', 'Camion 20 TN'],
        addr1:null,
        price:0,
        quantity:1,
        totalPrice:0,
        payment_method:null,
        addr2:null,
        responseMessage: null,
        produit: null,
        provider: null,
        selectedMethod: null,
        selectedWilaya1: {},
        selectedWilaya2: {},
        selectedCommunue1: null,
        selectedCommunue2: null,
        selectedDepot1: {},
        selectedDepot2: {},
        selectedVehicule: null,
        wilaya: null,
        posts: [],
        wilaya2: {dlv_type:'both'},
        checked: false,
        checked2: true,
        status: false,
        receiverphone:null,
        receivername:null,
        clientname: null,
        prix: null,
        phone: null,
        willaya: null,
        address: null,
        county: null,
        tracking_id: null,
        fais: 0,
        date: null,
        text: null,
        setset: null,
        fuckoff: null,
        allwilayas: [],
        willayas: [],
        communue: null,
        communues: [],
        deliveryPrice: 0,
        homePrice:0,
        weight:0,
      }
    },
    mounted(){
      this.date = new Date().toDateString()
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
      this.gettingdepots()
      this.gettingwilayas()
      // this.detectingprovider()
      if(this.fuckoff=='error'){
            this.$router.push({name : "Home"})
          }

    },
    methods:{
      gettingdepots(){
        axios.post('get-depots').then(response => {
            this.willayas = response.data.data
        })
      },
      gettingwilayas() {
        axios.post('get-wilayas').then(response => {
            this.allwilayas = response.data.data
            
        })
      },
      changeCommunues() {
        console.log(this.willaya)
        this.communues=this.willaya.communues
      },
      createDelivery() {
        this.responseMessage = null
        let client_wilaya = null
        let delivery_type = null
        if (this.checked && this.checked2) {
          delivery_type = 'Ramasser/Domicile'
        } else if (this.checked) {
          delivery_type = 'Ramasser'
        } else if (this.chekced2) {
          delivery_type = 'Domicile'
        } else {
          delivery_type = ''
        }
        if (this.willaya == null) client_wilaya = null
        else client_wilaya = this.willaya.name
        axios.post('create-colis-express',{
          depot1: this.selectedDepot1.email,
          depot2: this.selectedDepot2.email,
          auth_token: localStorage.getItem('auth_token'),
          produit: this.produit,
          quantity:this.quantity,
          payment_method:this.payment_method,
          weight:this.weight,
          wilaya1: this.selectedWilaya1.name,
          wilaya2: this.selectedWilaya2.name,
          communue1: this.selectedCommunue1,
          communue2: this.selectedCommunue2,
          addr1: this.addr1,
          addr2: this.addr2,
          text:this.text,
          clientname: this.clientname,
          phone: this.phone,
          receiverphone: this.receiverphone,
          receivername:this.receivername,
          delivery_price: this.deliveryPrice,
          price: this.price,
          total_price:this.totalPrice,
          delivery_type:delivery_type,
          home_price:this.homePrice
        }).then(response =>{
          if (response.data.status == 'error') {
            this.responseMessage = response.data.error_message
          } else {
            this.tracking_id = response.data.data.track_id
            this.setset = response.data.status
            // this.produit = null
            // this.wilaya1 = null
            // this.wilaya2 = null
            // this.checked = false
            // this.status = false
            // this.clientname = null
            // this.prix = null
            // this.phone = null
            // this.willaya = null
            // this.address = null
            // this.county = null
            // this.text = null
          }
        })
      },
      changeFees() {
        this.getFees()
      },
      getwilaya(name) {
        for (let i in this.allwilayas) {
          if (name == this.allwilayas[i].name) {
            return this.allwilayas[i]
          }
        }
      },
      // getFees() {
      //   this.fais = 0
      //   for (let i = 0; i < this.willayas.length; i++) {
      //     if (this.willayas[i].name == this.selectedDepot1.name) {
      //       this.addr1 = this.willayas[i].address
      //       this.selectedWilaya1 = this.getwilaya(this.willayas[i].wilaya)
      //       this.selectedCommunue1 = this.willayas[i].communue
      //       if (this.checked) {
      //         this.fais += this.willayas[i].collect_fees
      //       }
      //     }
      //     if (this.willayas[i].name == this.selectedDepot2.name) {
      //       this.addr2 = this.willayas[i].address
      //       this.selectedWilaya2 = this.getwilaya(this.willayas[i].wilaya)
      //       this.selectedCommunue2 = this.willayas[i].communue
      //       if (this.checked2) {
      //         this.fais += this.willayas[i].collect_fees
      //       }
      //     }
      //   }
      //   if (this.quantity == 0 || this.quantity == null) {
      //     this.quantity = 1
      //   }
      //   if (this.weight <= 5) {
      //     this.deliveryPrice = 400 * this.quantity + this.fais
      //   } else {
      //     this.deliveryPrice = (400 + parseInt(this.weight - 5) * 50) * this.quantity + this.fais
      //   }
      //   // this.deliveryPrice = (parseInt((this.weight - 0.01) / 10) + 1) * 400 + this.fais
      //   if (this.payment_method == 'Acheteur couvre tous les frais') this.totalPrice = parseInt(this.price) + parseInt(this.deliveryPrice)
      //   else if (this.payment_method == 'Acheteur couvre juste les frais de colis') this.totalPrice = parseInt(this.price)
      //   else if (this.payment_method == 'Acheteur couvre juste les frais de livraison') this.totalPrice = parseInt(this.deliveryPrice)
      //   else if (this.payment_method == 'Vendeur couvre tous les frais') this.totalPrice = 0
      //   // this.quantity = parseInt((parseFloat(this.weight) - 0.01) / 10) + 1
      // },
      getFees() {
        this.fais = 0
        for (let i = 0; i < this.willayas.length; i++) {
          if (this.willayas[i].name == this.selectedDepot1.name) {
            this.addr1 = this.willayas[i].address
            this.selectedWilaya1 = this.getwilaya(this.willayas[i].wilaya)
            this.selectedCommunue1 = this.willayas[i].communue
            if (this.checked) {
              this.fais += this.willayas[i].collect_fees
            }
          }
          if (this.willayas[i].name == this.selectedDepot2.name) {
            this.addr2 = this.willayas[i].address
            this.selectedWilaya2 = this.getwilaya(this.willayas[i].wilaya)
            this.selectedCommunue2 = this.willayas[i].communue
            if (this.checked2) {
              this.fais += this.willayas[i].collect_fees
            }
          }
        }
        if (this.selectedDepot1!=null && this.selectedDepot2!=null) {
            axios.post('get-btd', {
            auth_token: localStorage.getItem('auth_token'),
            depot1: this.selectedDepot1.id,
            depot2: this.selectedDepot2.id,
          }).then(response => {
            if (response.data.status == 'success') {
              this.responseMessage = null
              if (this.checked2) {
                this.fais = response.data.btd.delivery
                this.homePrice = response.data.btd.delivery - response.data.btd.stop_desk
              } 
              else {
                this.fais = response.data.btd.stop_desk
                this.homePrice = 0
              }
            } else if (response.data.error_message != undefined) {
              this.responseMessage = response.data.error_message
              if (this.checked2) {
                this.fais = 800
                this.homePrice = 400
              }
              else {
                this.fais = 400
                this.homePrice = 0
              }
            }
            if (this.quantity == 0 || this.quantity == null) {
              this.quantity = 1
            }
            if (this.weight <= 5) {
              this.deliveryPrice = this.fais * this.quantity
            } else {
              this.deliveryPrice = (parseInt(this.weight - 5) * 50) * this.quantity + this.fais
            }
            if (this.payment_method == 'Acheteur couvre tous les frais') this.totalPrice = parseInt(this.price) + parseInt(this.deliveryPrice)
            else if (this.payment_method == 'Acheteur couvre juste les frais de colis') this.totalPrice = parseInt(this.price)
            else if (this.payment_method == 'Acheteur couvre juste les frais de livraison') this.totalPrice = parseInt(this.deliveryPrice)
            else if (this.payment_method == 'Vendeur couvre tous les frais') this.totalPrice = 0
          })
        } else {
          this.fais = null
          this.responseMessage = null
        }
      },
    }
  }
</script>

<style>
.hide {
  display: none;
}
    
.myDIV:hover + .hide {
  display: block;
}
</style>